.wrapper {
  width: 100%;
  display: grid;
  gap: 8px;
  position: relative;
}

.input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  padding: 0 24px;
  height: 56px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 400;
  }

  &:focus {
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.error {
  color: #e73434;
  overflow-wrap: break-word;
  word-break: break-all;
}

.inputMultiple {
  min-height: 126px;
}

.info {
  position: absolute;
  right: 8px;
  top: 18px;
  color: var(--White, #e6e6e6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
