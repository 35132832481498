@import "../../../../styles/variables";

.component {
  padding: 16px 0 0;
  display: grid;
  gap: 20.5px;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  margin: 16px 0 0;

  @media (max-width: $vp-767) {
    padding: 16px 8px 0;
  }
}

.switchWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.switchLabel {
  color: #E6E6E6;
  font-size: 14px;
  font-weight: 400;
}

.wrapper {

}
