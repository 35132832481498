@import "../../styles/variables.scss";

.component {
  align-self: stretch;
}
.wrapper {
  @media (max-width: $vp-425) {
    width: 100%;
  }
}
.paginatorWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  @media (max-width: $vp-425) {
    gap: 4px;
  }
}
.page {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  width: 47px;
  height: 47px;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #16161a;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
  @media (max-width: $vp-375) {
    width: 42px;
    height: 42px;
  }
  @media (max-width: $vp-320) {
    border-radius: 12px;
    width: 38px;
    height: 38px;
  }
}
.currentPageBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 1) 0%,
    rgba(165, 79, 218, 1) 100%
  );
  border-radius: 16px;
  @media (max-width: $vp-375) {
    width: 42px;
    height: 42px;
  }
  @media (max-width: $vp-320) {
    border-radius: 12px;
    width: 38px;
    height: 38px;
  }
}
.currentPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 16px;
  border: 0px solid #4528e3;
  margin: 1px;
  // background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 0.25) 0%,
    rgba(165, 79, 218, 0.25) 100%
  );
  @media (max-width: $vp-375) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: $vp-320) {
    border-radius: 12px;
    width: 36px;
    height: 36px;
  }
}
.currentPageBlackBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background: #000;
  border-radius: 16px;
  @media (max-width: $vp-375) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: $vp-320) {
    border-radius: 12px;
    width: 36px;
    height: 36px;
  }
}
.div {
  display: flex;
  width: 23px;
  height: 47px;
  color: #fff;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: $vp-425) {
    display: none;
  }
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
.first {
  margin-right: 8px;
  @media (max-width: $vp-425) {
    margin-right: 0;
    display: none;
  }
}
.last {
  margin-left: 8px;
  @media (max-width: $vp-425) {
    margin-left: 0;
    display: none;
  }
}
