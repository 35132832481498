.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 200px;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.title {
  color: #e6e6e6;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.description {
  color: rgba(230, 230, 230, 0.6);
  text-align: center;
  font-size: 14px;
  line-height: 22px;
}
.buttonWrapper {
  margin-top: 16px;
}
