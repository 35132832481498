@import "../styles/variables.scss";

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100svh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0px 0px 48px 48px;
  background: #101013;
  width: 100%;
}

.footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectButtonWrapper {
  margin-top: 40px;
}
