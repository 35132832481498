@import "../../styles/variables.scss";

.component {
  display: flex;
  align-self: stretch;
}

.menuContainer {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -35%);
  border-radius: 16px;
  background: #16161a;
  display: flex;
  width: 430px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  @media (max-width: $vp-767) {
    width: 92%;
    box-sizing: border-box;
    padding: 24px;
  }
  @media (max-width: $vp-425) {
    width: 96%;
    box-sizing: border-box;
    padding: 16px;
  }
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menuTitle {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.menuButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.overflow {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.overflowTransparent {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.connectMenuItem2 {
  position: relative;
  display: flex;
  height: 72px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  cursor: pointer;

  > span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  &:hover {
    background: #1a1a21;
  }
}

.connectMenuItem {
  background: #292a2f;
  padding: 9px 16px;
  border-radius: 56px;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  display: inline-flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  outline: 1px solid rgba(255, 255, 255, 0);
  transition: 0.2s ease all;

  &:hover {
    outline: 2px solid rgba(255, 255, 255, 0.2);
  }

  > img {
    width: 32px;
    height: 32px;
  }

  > span {
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    display: flex;
    background: #34353b;
    border-radius: 32px;
    padding: 8px 12px;
    align-items: center;
    height: 32px;
    color: #fafafa;

    > img {
      margin-left: 4px;
    }
  }
}

.notinstall {
  color: #ababab;

  > img {
    opacity: 0.5;
  }

  > span {
    color: #fafafa;

    > img {
      opacity: 1;
    }
  }
}

.loggedInWrapper {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  display: flex;
  padding: 5px 0 6px;
  min-width: 147px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #282637;
  }
}

.loggedInContent {
  display: grid;
}

.walletAddress {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.shevron {
  transform: rotate(0);
  transition: 0.3s ease all;
}

.currentNetwork {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
  line-height: 16px;
}

.loggedInMenu {
  position: absolute;
  top: 52px;
  // left: 0;
  right: 0;
  display: grid;
  gap: 10px;
  // width: 100%;
  z-index: 5;
}

.logoutButton {
  display: flex;
  padding: 15px 16px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  min-width: 149px;

  &:hover {
    background: #282637;
  }
}

.disconnectText {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.connectWalletButton {
  padding: 16px 24px;
}

.link {
  cursor: pointer;
}

.installButton {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 20px;
  background: linear-gradient(94deg, #4528e3 0%, #a54fda 100%);
  border: none;
  margin-left: auto;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  outline: 1px solid rgba(255, 255, 255, 0);
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background: linear-gradient(64deg, #4528e3 0%, #a54fda 100%);
    outline: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.logWrapperBtn {
  position: relative;
}
