@import '../../styles/variables.scss';

.component {
  background: #101013;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  padding: 17px 0;
  gap: 8px;

  @media (max-width: $vp-1024) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: $vp-767) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0 16px;
    row-gap: 16px;
  }
}

.switchWrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  border-radius: 56px;
  align-items: center;

  @media (max-width: $vp-767) {
    width: 100%;
    margin-left: auto;
    order: 2;
  }
}

.logoWrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  & > a {
    text-decoration: none;
  }
}

.txtLogo {
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin: 0;

  background: radial-gradient(
    60.53% 60.53% at 35.63% 28.95%,
    #ff7932 0%,
    #fcdfcf 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: $vp-320) {
    display: none;
  }
}

.menuItems {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1;
  @media (max-width: $vp-767) {
    width: 100%;
    flex-basis: 100%;
    order: 3;
    gap: 24px;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 1px;
      background-color: #101013;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #101013;
    }
  }
}

.menuItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all ease 0.3s;
  white-space: nowrap;

  &:hover {
    color: #8f7aff;
  }
}

.menuItemWrapperActive {
  color: #8f7aff;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: rgba(143, 122, 255, 1);
    bottom: -35px;
    left: 0;
    @media (max-width: $vp-767) {
      bottom: -16px;
    }
  }
}

.buttonsWrapper {
  margin-left: auto;
}
.stx20Link {
  border-radius: 40px;
  background: #292a2f;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: inline-flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  @media (max-width: 410px) {
    display: none;
  }
}
