@import '../../styles/variables';

.component {
  width: 100%;
  margin-top: 33px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretchs;
  gap: 4px;
}
.topWrapper {
  display: grid;
  grid-template-columns: 40px auto 40px;
  padding-bottom: 32px;
  gap: 4px;
}
.backWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inscriptionWrapper {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 650px) {
    flex-direction: column;
    gap: 24px;
    max-width: 440px;
    align-items: center;
    width: 100%;
  }
}
.imageWrap {
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  & > img {
    border-radius: 16px;
    @media (max-width: $vp-1100) {
      max-width: 100%;
    }
    @media (max-width: 650px) {
      max-width: 80%;
    }
    @media (max-width: 440px) {
      max-width: 70%;
    }
    @media (max-width: $vp-320) {
      max-width: 100%;
    }
  }
}
.infoWrap {
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-self: stretch;
  flex: 1;
  @media (max-width: 440px) {
    margin: 0 -40px;
  }
}
.name {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  width: 100%;
  @media (max-width: 440px) {
    font-size: 24px;
    line-height: 33px;
  }
  & > span {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
  }
}
.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  @media (max-width: 440px) {
    gap: 18px;
  }
}
.infoName {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  white-space: nowrap;
  @media (max-width: 440px) {
    white-space: normal;
  }
}
.infoHr {
  flex: 1;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.07);
  min-width: 32px;
  @media (max-width: 440px) {
    min-width: 24px;
  }
}
.infoValue {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  text-align: right;

  .link {
    color: #8f7aff;
    &:hover {
      cursor: pointer;
    }
  }
}
.imageWrapSkeleton {
  width: 40vw;
  height: 40vw;
  overflow: hidden;
  flex: 1;
}
.placeHolderBg {
  background: linear-gradient(100deg, #16161a, #222225, #16161a);
  background-size: 240% 240%;
  animation: gradient-animation 3s ease infinite;
  flex: 1;
  aspect-ratio: 1 / 1;
  height: 100%;
  overflow: hidden;
  max-width: 440px;
  max-height: 440px;
  border-radius: 16px;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.copyButton {
  min-width: 100%;
}
.copyButtonHalf {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  min-width: 100%;
}
.transferButton {
  width: 100%;
  border-radius: 12px;
}
.infoColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}
.hr {
  opacity: 0.05;
  background: #e6e6e6;
  width: 100%;
  height: 1px;
}
.bottomWrapper {
  display: flex;
}
