@import '../../styles/variables.scss';

.component {
  position: relative;
  @media (max-width: 820px) {
    flex-basis: 49%;
  }
  @media (max-width: 400px) {
    flex-basis: 100%;
    width: 100%;
  }
}
.selectedWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  // padding: 14px 18px;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  // min-width: 236px;
}
.caption {
  display: flex;
  align-items: center;
  gap: 8px;
}
.label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 19px;
  @media (max-width: 540px) {
    font-size: 12px;
    line-height: 16px;
  }
}
.title {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  @media (max-width: 540px) {
    font-size: 13px;
    line-height: 18px;
  }
}
.menuBg {
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.menuItems {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  position: absolute;
  z-index: 3;
  top: 55px;
  right: 0;
  width: 100%;
  overflow: hidden;
  padding: 8px 0;
}
.menuItem {
  display: flex;
  padding: 15px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  @media (max-width: 535px) {
    padding: 15px 8px;
  }
}
.arrow {
  transition: all 0.5s ease;
  transform: rotate(-180deg);
}
.arrowOpened {
  transform: rotate(0deg);
}
