@import "../../../../../../styles/variables";

.container {
  display: flex;
  gap: 12px;
  padding: 16px 48px 16px 16px;
  background: rgba(43, 29, 29, 0.82);
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 375px;
  position: relative;

  @media (max-width: $vp-767) {
    background: #261B1B;
  }
}

.closeError {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.05);
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

.wrapper {
}

.filename {
  color: rgba(220, 220, 220, 0.7);
  font-size: 13px;
  font-weight: 400;
}

.errorMessageWrapper {
  display: grid;
  gap: 2px;
}

.errorMessage {
  color: #DCDCDC;
  font-size: 13px;
  font-weight: 700;
}
