@import '../../../../styles/variables';

.container {
  border-radius: 16px;
  border: 1px dashed rgba(255, 255, 255, 0.05);
  background: rgba(22, 22, 26, 1);
  min-height: 126px;
  max-width: 890px;
  width: 100%;
  transition: all ease 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 0 18px;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: $vp-767) {
    padding: 8.5px 16px;
  }
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.containerFocused {
  background: rgb(37, 37, 44);
}

.titleWrapper {
  display: grid;
  align-items: center;
  gap: 2px;
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.subTitle {
  color: rgba(230, 230, 230, 0.6);
  font-size: 14px;
  font-weight: 400;

  @media (max-width: $vp-767) {
    font-size: 14px;
  }
}

.cloudWhenHaveItems {
  display: flex;
  width: 80px;
  height: 51px;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  border: 1px dashed rgba(255, 255, 255, 0.05);
  background: #222225;
}

.errorsWrapper {
  position: fixed;
  top: 200px;
  right: 0;
  display: grid;
  gap: 10px;
  @keyframes fadeIn {
    from {
      transform: translateX(110%);
    }
    to {
      transform: translateX(0);
    }
  }
  animation: fadeIn 0.2s ease;
}
