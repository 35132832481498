.component {
  display: flex;
  padding: 8px 8px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #16161a;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
}
.imageWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
}
.text {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
}
.type {
  border-radius: 8px 0px;
  border-right: 3px solid #16161a;
  border-bottom: 3px solid #16161a;
  background: #383841;
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 2px 8px;
  color: #a2a2b5;
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
.caption {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
.date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.componentSkeleton {
  min-height: 200px;
}
.skeleton {
  overflow: hidden;
}
.skeletonImage {
  width: 100%;
  height: 123px;
}
.priceWrapper {
  display: flex;
  align-items: center;
  gap: 2px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  flex: 1;
}
.icon {
  width: 16px;
  height: 16px;
}

.codeWrapper {
  background: #eee;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
  max-width: 80ch;
}

.codeContent {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-bottom: -8px;
}
