.loader {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loaderMini {
  width: 16px;
  height: 16px;
}
.noMargin {
  margin: 0;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #fff;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}
.loader::before {
  background-color: #522de1;
  transform: scale(0.5) translate(-24px, -24px);
}
.loaderMini::after,
.loaderMini::before {
  width: 8px;
  height: 8px;
}
.loaderMini::before {
  transform: scale(0.5) translate(-16px, -16px);
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
