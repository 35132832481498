.wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 8px;
}
.contentWrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.title {
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.text {
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.button {
  margin-top: 8px;
  max-width: 100%;
  width: 100%;
}
.image {
  margin: 0 auto;
}
