.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title {
  color: #e6e6e6;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.buttonWrapper {
  margin-top: 8px;
}
.button {
  width: 100%;
}
