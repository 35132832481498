.component {
  width: 100%;
  position: relative;
}
.input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  padding: 16px 24px;
  min-height: 126px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  resize: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 400;
  }

  &:focus {
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.error {
  color: #e73434;
  overflow-wrap: break-word;
  word-break: break-all;
}
