.component {
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}
.withTitle {
  width: 112px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  display: flex;
  align-items: center;
  gap: 8px;
}
.title {
  color: #e6e6e6;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
