@import "../../../../../../styles/variables";

.component {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  width: 173px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;

  @media (max-width: $vp-767) {
    width: 170px;
  }
}

.roundButton {
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.itemIndex {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  width: 24px;
  height: 24px;
}

.wrapper {
  display: grid;
  gap: 2px;
  flex: 1;
}

.filename {
  color: #FFF;
  font-size: 13px;
  font-weight: 700;
}

.fileSize {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 400;
}

.delete {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}
