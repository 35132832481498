@import "../../styles/variables";

.component {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  @keyframes fadeIn {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  animation: fadeIn 0.6s ease;
}

.wrapper {
  z-index: 11;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 430px;
  width: 100%;
  max-height: 530px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #16161A;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: $vp-767) {
    max-width: 360px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @keyframes showUp {
    from {
      opacity: 0;
      top: -100px;
    }
    to {
      opacity: 1;
      top: 100px;
    }
  }
  animation: showUp 0.4s ease;
}

.contentWrapper {
  padding: 32px;
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}
