@import '../../styles/variables.scss';
.component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  width: 100%;
  min-width: 290px;
  height: 46px;
  padding: 0 16px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 0 12px;
    min-width: unset;
  }
  @media (max-width: 540px) {
    padding: 0 8px;
  }
}
.componentError {
  border: 1px solid #ff5b5b;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}
.input {
  background: #101013;
  width: 100%;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
    border: none;
  }
  @media (max-width: 540px) {
    font-size: 13px;
  }
}
.icon {
  opacity: 0.6;
}
.error {
  color: #ff5b5b;
  position: absolute;
  left: 0;
  bottom: -22px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}
