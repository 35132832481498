.component {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  max-width: 100%;
  overflow-x: scroll;

  padding-bottom: 4px;
  &::-webkit-scrollbar {
    height: 3px;
    background-color: #101013;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.item {
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  color: #e6e6e6;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  cursor: pointer;
}
.itemSelected {
  border-radius: 48px;
  border: 1px solid var(--Accent, #4528e3);
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 0.1) 0%,
    rgba(165, 79, 218, 0.1) 100%
  );
}
