.toggleWrapper {
  width: 48px;
  height: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 13px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 1s ease;
  background: rgb(101, 101, 101) linear-gradient(to right, rgba(74, 42, 227, 0) 0%, rgba(161, 78, 219, 0) 100%);
}

.toggleWrapperChecked {
  background: rgb(101, 101, 101) linear-gradient(to right, rgba(74, 42, 227, 1) 0%, rgba(161, 78, 219, 1) 100%);
}

.toggle {
  width: 20px;
  height: 20px;
  background: #FFF;
  border-radius: 50%;
  transform: translateX(0);
  transition: all 0.2s ease;
}

.toggleChecked {
  transform: translateX(20px);
}
