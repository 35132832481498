.component {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #16161a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}
