.image {
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
  image-rendering: pixelated;
}
.imageDefault {
  max-width: 48px;
}
.bgDefault {
  width: 100%;
  max-height: 183px;
  height: 183px;
  background: #262633;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.nsfw {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background:
    linear-gradient(0deg, #28262f 0%, #28262f 100%),
    lightgray 50% / cover no-repeat;
  width: 100%;
  height: 100%;

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 500;
  }
}

.smallItem .nsfw {
  border-radius: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.videoPreview {
  opacity: 0.7;
}
.overlayMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.smallItem,
.smallItem model-viewer {
  width: 100%;
  // max-width: 183px;
  // max-height: 183px;
  height: 183px;
  // width: 183px;
  overflow: hidden;
}
.text {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  color: white;
  max-width: 80vw;
  padding: 8px;
  box-sizing: border-box;
  @media (min-width: 400px) {
    max-width: 40vw;
  }
  @media (min-width: 600px) {
    max-width: 26vw;
  }
  @media (min-width: 800px) {
    max-width: 19vw;
  }
  @media (min-width: 1000px) {
    max-width: 19vw;
  }
  @media (min-width: 1200px) {
    max-width: 148px;
  }
  @media (min-width: 1300px) {
    max-width: 164px;
  }
  @media (min-width: 1400px) {
    max-width: 177px;
  }
  @media (min-width: 1440px) {
    max-width: 183px;
  }
}

.textSmall {
  padding: 25px 9px 9px 9px;
  box-sizing: border-box;
  background-color: #262633;
  border-radius: 8px;
}

.bigItem,
.bigItem model-viewer {
  width: 100%;
  max-width: 450px;
  //max-height: 450px;
  height: 100%;
  // overflow: auto;
  background-color: #262633;
  & > .text {
    max-width: 100%;
    overflow: auto;
  }
  min-height: 220px;
  .bgDefault {
    max-height: 100%;
    height: 100%;
    min-height: 220px;
  }
  border-radius: 16px;
}

.bigItem:has(>img) {
  background-color: transparent;
  max-height: none;
  height: auto;
  img {
    background-color: transparent;
  }
}

.bigItem:has(.nsfw) {
  max-height: 450px;
}

.bigItem:has(.text) {
  &, &.text {
    max-height: 450px;
  }
}


.bigItem:has(>audio) {
  background-color: transparent;
  max-height: none;
}

.bigItem:has(>audio) {
  &, &>audio {
    background-color: transparent;
  }
  & > audio {
    height: 60px
  }
}
.bigItem {
  & > img {
    border-radius: 16px;
    object-fit: cover;
    max-height: none;
  }
  border-radius: 16px;
}

.codeWrapper {
  background: rgb(1, 22, 39);
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
  color: rgb(214, 222, 235);
  box-sizing: border-box;
}
.codeContent {
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  text-wrap: pre;
}
