@import "../../styles/variables";

.wrapper {
  margin: 0 auto;

  @media (max-width: $vp-767) {
    width: 93.5%;
  }

  @media (min-width: $vp-768) {
    width: 89.4%;
  }

  @media (min-width: $vp-1024) {
    width: 89.4%;
  }

  @media (min-width: $vp-1440) {
    // width: 89.4%;
    width: 1352px;
  }

  // @media (min-width: $vp-1512) {

  // }
}
