@import '../../styles/variables';

.component {
  width: 100%;
  margin-top: 24px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}
.childrenWrapper {
  width: 100%;
}
.inscriptionsWrapper {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}
.inscriptionList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @media (min-width: 400px) {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
  }
  @media (min-width: 900px) {
    gap: 16px;
  }
  @media (min-width: 1000px) {
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
  }
  @media (min-width: 1100px) {
    gap: 24px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 16px;
  }
  @media (min-width: 1440px) {
    gap: 32px;
  }
}
.inscriptionListSkeleton {
  position: relative;
}

.headWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $vp-768) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }
}
.captionWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex: 1;
}
.count {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}
.caption {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
.actionsWrap {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  @media (max-width: $vp-768) {
    gap: 12px;
    width: 100%;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
}
.dropdown {
  min-width: 214px;
  padding: 13px 23px;
  @media (max-width: 540px) {
    padding: 13px 8px;
  }
  @media (max-width: 480px) {
    min-width: 160px;
  }
}
