@import '../../styles/variables';

.component {
  display: grid;
  gap: 24px;
}

.headerWrapper {
  display: grid;
  gap: 12px;
  max-width: 890px;
  width: 100%;
  margin: 64px auto 0;
  position: relative;

  @media (max-width: $vp-767) {
    margin: 40px auto 37px;
  }
}

.title {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin: 0;

  @media (max-width: $vp-767) {
    font-size: 24px;
  }
}

.warning {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid #ff5b5b;
  background: rgba(255, 48, 48, 0.05);
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.subtitle {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: $vp-767) {
    font-size: 13px;
  }
}

.radioButtonsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.radioItem {
  display: flex;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}

.radioDongle {
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.radioItemActive {
  color: #fff;
  border: unset;

  .radioDongle {
    position: relative;
    background: linear-gradient(220deg, #a24edb 0%, #4a2ae3 100%);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background: #ffffff;
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
  }
}

.multipleInputTitle {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.submitButton {
  margin-left: auto;
  width: 183px;
  height: 56px;

  @media (max-width: $vp-767) {
    width: 100%;
  }
}

.error {
  color: #e73434;
  overflow-wrap: break-word;
  word-break: break-all;
}

.destinationWrapper {
  border-radius: 16px;
  background: #16161a;
  padding: 24px;
  display: grid;

  @media (max-width: $vp-767) {
    padding: 12px 6px 12px;
  }
}

.destinationContainer {
  display: grid;
}

.destinationInputsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $vp-767) {
    padding: 8px;
  }
}

.destinationWarning {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;

  margin: 0 0 12px;

  @media (max-width: $vp-767) {
    margin: 0 0 6px;
  }
}

.destinationInput {
  //margin: 0 0 24px;
  //
  // uncomment when name and description available
  //
  //@media (max-width: $vp-767) {
  //  margin: 0 0 12px;
  //}
}

.shortInput {
  max-width: 409px;

  @media (max-width: $vp-1024) {
    max-width: 100%;
  }
}

.docLink {
  color: #8f7aff;
}
