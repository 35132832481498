@import '../../styles/variables.scss';

.component {
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 18px 0;

  @media (max-width: $vp-767) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
  }
}

.docsWrapper {
  position: relative;
}

.crWrapper {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;

  .authorLink {
    color: #e6e6e6;
    font-size: 13px;
    line-height: 18px;
  }
}
.link {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.6;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
}

.socialsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

.social {
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 0.8;
  }
}

.socialDisable {
  opacity: 0.4;
}

.soon {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.6;
}
